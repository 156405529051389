<template>
  <div v-if="event">
    <h1>{{ event.title }}</h1>
    <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
    <p>{{ event.description }}</p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      event: null,
    };
  },
  created() {
    axios
      .get(
        "https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3/events/" +
          this.id
      )
      .then((response) => {
        console.log(response.data);
        this.event = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
